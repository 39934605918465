<template>
  <van-popup
    v-model="selectAreaShow"
    round
    position="bottom"
    get-container="body"
  >
    <van-cascader
      :value="defaultValue"
      title="选择省市区"
      :options="areaOptions"
      @close="selectAreaShow = false"
      @finish="finish"
      active-color="#00a1ff"
      :field-names="fieldNames"
    />
  </van-popup>
</template>

<script>
import { area } from "@/api/index.js";

export default {
  components: {

  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    defaultValue:{
        type: [Number, String],
        default: '',
    }
  },
  data() {
    return {
      area: '',
      areaOptions: [],
      fieldNames: { text: 'name', value: 'id', children: 'children' },
    }
  },
  mounted() {
    this.getArea();
  },
  methods: {
    finish(value) {
      this.selectAreaShow = false;
      let area = value.selectedOptions[0]['name'] + value.selectedOptions[1]['name'] + value.selectedOptions[2]['name'];
      this.$emit("finish", value, area);
    },
    getArea() {
      area()
        .then(res => {
          if (res.code == 0) {
            this.areaOptions = res.data;
          } else {
            this.$toast(res.msg);
          }
        })
    }
  },
  computed: {
    selectAreaShow: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>