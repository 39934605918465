<template>
  <div class="comtain">
    <navbar :title="id ? '添加' : '编辑' + '收货地址'"></navbar>
    <div class="form-block">
      <van-form ref="form" :show-error-message="false" :show-error="false">
        <div class="form-con">
          <div class="cell">
            <span class="red">*</span>
            <van-field
              v-model="form.consignee"
              name="consignee"
              label="收货人"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写收货人' }]"
            />
          </div>
          <div class="cell">
            <span class="red">*</span>
            <van-field
              v-model="form.mobilephone"
              name="mobilephone"
              label="手机号码"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写手机号码' },{validator: isMobile, 'message': '手机号不正确'}]"
            />
          </div>
          <div class="cell">
            <span class="red">*</span>
            <van-field
              v-model="item.area"
              readonly
              clickable
              name="area"
              label="所在地区"
              placeholder="选择省市区"
              right-icon="arrow-down"
              @click="selectAreaShow=true;"
              :rules="[{ required: true, message: '请选择省市区' }]"
            />
          </div>
          <div class="cell">
            <span class="red">*</span>
            <van-field
              v-model="form.address"
              name="address"
              label="详细地址"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写详细地址' }]"
            />
          </div>
          <div class="cell">
            <div class="cell-c">
              <div>设为默认地址</div>
              <van-switch v-model="form.is_default" />
            </div>
          </div>
        </div>
        <div class="btn">
          <van-button class="ch-btn" round block type="info-cus" @click="onSubmit"><span class="ch-btn-txt">保存</span></van-button>
        </div>
      </van-form>
    </div>
    <area-cascader
      v-model="selectAreaShow"
      :defaultValue="item.district_id"
      @finish="areaFinish"
    ></area-cascader>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import AreaCascader from '@/components/area-cascader.vue';
import { isMobile } from "@/lib/utils.js";
import {addressDetail, addressUpdate, addressCreate} from '@/api/address.js'

export default {
  name: 'addressDetail',
  components: {
    Navbar,
    AreaCascader
  },
  data() {
    return {
      id: 0,
      form: {},
      item: {},
      selectAreaShow: false
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    if (this.id) this.getDetail()
  },
  methods: {
    isMobile,
    onSubmit() {
      this.$refs.form.validate().then(res => {
        let data = {...this.form}
        data.is_default = data.is_default ? 1 : 0
        delete data.detailed_area
        data.province_id = this.item.province_id
        data.city_id = this.item.city_id
        data.district_id = this.item.district_id
        if (this.id) {
          //编辑
          addressUpdate(data)
            .then(res => {
              if(res.code == 0) {
                this.$toast('操作成功')
                this.$router.go(-1)
              } else {
                this.$toast(res.msg)
              }
            })
        } else {
          //新增
          addressCreate(data)
            .then(res => {
              if(res.code == 0) {
                this.$toast('操作成功')
                this.$router.go(-1)
              } else {
                this.$toast(res.msg)
              }
            })
        }
      }).catch(err => {
        if (err[0] != undefined) {
          this.$toast(err[0]['message']);
        }
      })
    },
    areaFinish(value, area) {
      this.$set(this.item, 'area', area);
      this.$set(this.item, 'province_id', value.selectedOptions[0]['id']);
      this.$set(this.item, 'city_id', value.selectedOptions[1]['id']);
      this.$set(this.item, 'district_id', value.selectedOptions[2]['id']);
    },
    getDetail() {
      addressDetail({id: this.id})
        .then(res => {
          if (res.code == 0) {
            this.form = res.data
            this.form.is_default = this.form.is_default ? true : false
            this.item = {
              area: this.form.detailed_area,
              province_id : this.form.province_id,
              city_id : this.form.city_id,
              district_id : this.form.district_id,
            }
          } else {
            this.$toast(res.msg)
            this.$router.go(-1)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.comtain{
  background: #fff !important;
  height: 100vh;
}
.form-block{
  .form-con{
    padding-top: 10px;
    @include safe-area-inset-bottom('170px');
    .cell{
      position: relative;
      margin: 20px 20px 0;
      .red{
        position: absolute;
        left: 20px;
        top: 27px;
        color: #EB3C3C;
        font-size: 30px;
        z-index: 1;
      }
      .van-cell{
        padding: 26px 30px 26px 42px;
        border-radius: 10px;
        font-size: 30px;
      }
      :deep(.van-field__label){
        width: 200px;
        font-size: 30px;
        color: #4F4F4F;
        font-weight: bold;
      }
      .cell-c{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 26px 30px 26px 42px;
        background: #F4F9FC;
        border-radius: 10px;
        font-size: 30px;
        color: #4F4F4F;
        font-weight: bold;
      }
    }
  }
 .btn{
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 30px 30px 0;
  @include safe-area-inset-bottom('30px');
  background: #fff;
  box-sizing: border-box;

  left: 0;
  .ch-btn {
    .ch-btn-txt{
      font-size: 36px;
    }
  }
 }
}
</style>